import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

/**
 * Namespaces of i18next.
 */
export enum I18Namespace {
  General = 'general',
  Oow = 'oow',
  Qr = 'qr',
  Oba = 'oba',
  Com = 'com',
  Lg = 'lg',
  LgQr = 'lg-qr',
  MgmInternal = 'mgm-internal',
  MgmExternal = 'mgm-external',
  Optin = "optin",
  SfpToken = "sfp-token",
  StoreLocations = "store-locations",
  AnniversaryCards = "anniversary-cards",
  CC = "cc"
}

export enum Language {
  Georgian = "ka",
  English = "en",
}

function getLanguage() {
  const language = new URLSearchParams(window.location.search).get("language");
  // @ts-ignore
  return language !== null && Object.values(Language).includes(language) ? language : Language.Georgian;
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    lng: getLanguage(),
    fallbackLng: [Language.Georgian, Language.English],
    debug: (process.env.NODE_ENV || "development") === "development",
    interpolation: {
      // Not needed for react as it escapes by default.
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br"],
      useSuspense: true,
    },
    defaultNS: I18Namespace.General,
    ns: [I18Namespace.General],
    fallbackNS: I18Namespace.General,
    load: 'currentOnly',
  });

export default i18n;
